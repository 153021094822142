<template>
  <div>
    <UserSettings ref="userSettingsDialog" :lockViewerOptions="true"/>
    <WorksInProgress ref="wDialog" title="Viewer"/>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand class="d-none d-sm-block" href="#"><img src="../assets/logo.png" class="d-inline-block align-top" alt="Saince" height="30"></b-navbar-brand>
      <b-navbar-nav>
        <b-nav-text class="fixedHeight">&nbsp;</b-nav-text>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button-group>
          <b-button @click="closeStudy" variant="secondary" :size="buttonSize" title="Close Study">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
        <b-dropdown class="ml-2" title="User" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="person"></b-icon>
          </template>
          <b-dropdown-header>{{user}}</b-dropdown-header>
          <b-dropdown-item @click="userSettings"><b-icon icon="gear-fill"></b-icon> Settings</b-dropdown-item>
          <b-dropdown-item @click="profile"><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item>
          <b-dropdown-item @click="logout"><b-icon icon="box-arrow-right"></b-icon> Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
<script>
import UserSettings from './UserSettings.vue'
import WorksInProgress from './WorksInProgress.vue'

export default {
  components: {
    UserSettings,
    WorksInProgress
  },
  data() {
    return {
      buttonSize: "sm"
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    user() {
      return this.$store.getters.user || 'User Name Not Set'
    }
  },
  methods: {
    closeStudy() {
      this.$router.replace('worklist')
    },
    help() {
      this.$refs.wDialog.show()
    },
    handleResize() {
      this.buttonSize = (window.innerWidth < 767) ? "sm" : "md"
    },
    logout() {
      this.$router.replace('logout')
    },
    userSettings() {
      this.$refs.userSettingsDialog.show()
    },
    profile() {
      try {
        window.open(this.$store.state.keycloak.createAccountUrl());
      }
      catch(err) {
        this.$log.error('Failed to open account profile: '+err);
      }
    }
  }
}    
</script>
<style scoped>
.fixedHeight {
  height: 36px;
  min-height: 36px;
}
</style>